import React, { useState } from 'react';
import Loader from '../../components/Loader';
import { Grid, Button, Dialog, Select, MenuItem, Slider } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import LockIcon from '@mui/icons-material/Lock';
import TextInput from '../../components/formik/TextInput';
import Checkbox from '../../components/formik/Checkbox';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  cell: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  defineButton: {
    height: '2rem',
    alignSelf: 'center',
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  icon: {
    paddingBottom: '5px',
    marginLeft: '5px',
  },
}));

function Schema({ detailsRequest, formikProps, access }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [valDataNum, setValDataNum] = useState(0);
  const { loading, error } = detailsRequest;
  const inputsDisabled = access === 'view';
  const classes = useStyles();

  if (loading || error) return <Loader />;

  function defineClick(dataNum) {
    setValDataNum(dataNum);
    setDialogOpen(true);
  }

  function closeDialog() {
    setDialogOpen(false);
  }

  const { values } = formikProps;
  const fieldLength = 21;
  // make array from 0-length
  const fieldArray = Array.from({ length: fieldLength }, (_, i) => i);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4} className={classes.cell}>
          <b>Column Labels</b>
        </Grid>
        <Grid item xs={4} className={classes.cell}>
          <b>Data Validation</b>
        </Grid>
        <Grid item xs={4} className={classes.cell}>
          <b>Description</b>
        </Grid>
        {fieldArray.map((i) => {
          const subFieldName = i === 0 ? 'key' : `data${i}`;
          const field = values?.schema?.[subFieldName];
          const fieldName = `schema.${subFieldName}`;

          return (
            <React.Fragment key={i}>
              <Grid item xs={4} className={classes.cell}>
                <TextInput
                  label=''
                  name={`${fieldName}.name`}
                  disabled={inputsDisabled}
                  // If this is the first item, I.E. the key, add a little key icon.
                  InputProps={
                    i === 0
                      ? {
                          endAdornment: <KeyIcon />,
                        }
                      : {}
                  }
                />
              </Grid>
              <Grid item xs={4} className={classes.cell}>
                <Checkbox
                  label=''
                  name={`${fieldName}.validationOn`}
                  isSwitch
                  disabled={i === 0 || inputsDisabled}
                />
                <Button
                  variant='outlined'
                  className={classes.defineButton}
                  disabled={!field?.validationOn || inputsDisabled}
                  onClick={() => defineClick(i)}
                >
                  Define
                </Button>
              </Grid>
              <Grid item xs={4} className={classes.cell}>
                <TextInput
                  label=''
                  name={`${fieldName}.desc`}
                  disabled={inputsDisabled}
                />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>

      <SchemaDialog
        dialogOpen={dialogOpen}
        valDataNum={valDataNum}
        closeDialog={closeDialog}
        formikProps={formikProps}
      />
    </div>
  );
}

export default Schema;

const useDialogStyles = makeStyles((theme) => ({
  root: {
    padding: '40px',
    width: '400px',
  },
  row: {
    display: 'flex',
  },
  dropdown: {
    width: '100%',
  },
}));

function SchemaDialog(props) {
  const { dialogOpen, valDataNum, closeDialog, formikProps } = props;
  const { values, setFieldValue } = formikProps;
  const classes = useDialogStyles();

  const subFieldName = valDataNum === 0 ? 'key' : `data${valDataNum}`;
  const fieldName = `schema.${subFieldName}.validation`;
  return (
    <Dialog open={dialogOpen} onClose={closeDialog}>
      <div className={classes.root}>
        <h3>
          {valDataNum === 0
            ? values?.schema?.key?.name
            : values?.schema[`data${valDataNum}`]?.name}{' '}
          Validation
        </h3>

        <div>
          <Checkbox
            // If this is the key field (valDataNum === 0), then we do some special
            // rendering on the checkbox.
            label={
              valDataNum === 0 ? (
                <div className={classes.inline}>
                  <span>Always Required</span>
                  <LockIcon fontSize='small' style={{ verticalAlign: 'sub' }} />
                </div>
              ) : (
                'Always Required'
              )
            }
            name={`${fieldName}.required`}
            // Conditionally add the checked prop, because it immediately changes to
            // uncontrolled if that prop is there.
            {...(valDataNum === 0 ? { checked: true } : {})}
          />
        </div>
        <div>
          <Checkbox
            label='No Duplicates in Column'
            name={`${fieldName}.disallow_duplicates`}
          />
        </div>
        <div>
          <p>Data Type</p>
          <div className={classes.row}>
            <Checkbox label='' name={`${fieldName}.data_type_on`} isSwitch />
            <Select
              value={
                (values?.schema &&
                  values?.schema[subFieldName]?.validation?.data_type) ||
                'int'
              }
              onChange={(e) => {
                setFieldValue(`${fieldName}.data_type`, e.target.value);
              }}
              disabled={
                (values?.schema &&
                  !values?.schema[subFieldName]?.validation?.data_type_on) ||
                false
              }
              className={classes.dropdown}
            >
              <MenuItem value='int'>Integer (int)</MenuItem>
              <MenuItem value='float'>Floating point (float)</MenuItem>
              <MenuItem value='date'>Date (date)</MenuItem>
              <MenuItem value='time'>Time (time)</MenuItem>
              <MenuItem value='datetime'>Date and Time (datetime)</MenuItem>
              <MenuItem value='email'>Email (email)</MenuItem>
              <MenuItem value='regex'>Regular Expression (regex)</MenuItem>
            </Select>
          </div>
        </div>
        <div>
          <p>Check for Spaces</p>
          <div className={classes.row}>
            <Checkbox
              label=''
              name={`${fieldName}.check_for_spaces_on`}
              isSwitch
            />
            <Select
              value={
                (values?.schema &&
                  values?.schema[`data${valDataNum}`]?.validation
                    ?.check_for_spaces) ||
                'lead'
              }
              onChange={(e) => {
                setFieldValue(`${fieldName}.check_for_spaces`, e.target.value);
              }}
              disabled={
                values?.schema &&
                !values?.schema[subFieldName]?.validation?.check_for_spaces_on
              }
              className={classes.dropdown}
            >
              <MenuItem value='lead'>Check for leading spaces</MenuItem>
              <MenuItem value='trail'>Check for trailing spaces</MenuItem>
              <MenuItem value='both'>Check for both</MenuItem>
              <MenuItem value='any'>Check for ANY spaces</MenuItem>
            </Select>
          </div>
        </div>
        <div>
          <p>Field Length</p>
          <div className={classes.row}>
            <Checkbox label='' name={`${fieldName}.field_length_on`} isSwitch />
            <Slider
              value={[
                values?.schema &&
                  values?.schema[subFieldName]?.validation?.field_length_min,
                values?.schema &&
                  values?.schema[subFieldName]?.validation?.field_length_max,
              ]}
              onChange={(_, values) => {
                setFieldValue(`${fieldName}.field_length_min`, values[0]);
                setFieldValue(`${fieldName}.field_length_max`, values[1]);
              }}
              disabled={
                (values.schema &&
                  !values?.schema[subFieldName]?.validation?.field_length_on) ||
                false
              }
              valueLabelDisplay='auto'
              step={1}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}
