import React from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { Route, PrivateRoute, EulaRoute } from './';
import * as pages from '../../pages';
import {
  ADMIN,
  CIS_INTERNAL,
  IBANK,
  RISK_MESSENGER,
  USER,
} from './../auth/constants';

const Router = ({
  color,
  setColor,
  logo,
  setLogo,
  isNewLogo,
  setIsNewLogo,
  orgConfig, //HD-147
  setLoading,
}) => {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from='/' to='/home' />
        <Route path={['/login', '/signin']} component={pages.Login} noLayout />
        <Route
          path='/forgot-password'
          component={pages.ForgotPassword}
          noLayout
        />
        <Route
          path='/change-password'
          component={pages.ChangePassword}
          noLayout
        />
        <Route
          path='/confirm-password-reset'
          component={pages.ConfirmPasswordReset}
          noLayout
        />
        <Route path='/access-denied' component={pages.AccessDenied} />
        <Route path={['/logout', '/signout']} component={pages.Logout} />
        <Route
          path='/authorizations'
          component={pages.Authorizations}
          noLayout
        />
        <EulaRoute path='/eula' component={pages.Eula} noLayout />
        <PrivateRoute
          path='/home'
          component={pages.Home}
          role={USER}
          componentProps={{ logo, isNewLogo, orgConfig }}
        />
        <PrivateRoute
          path='/users'
          component={pages.Users}
          role={ADMIN}
          componentProps={{
            setLoading: setLoading,
            logo,
            isNewLogo,
            orgConfig,
          }}
        />
        <PrivateRoute
          path='/user/me'
          component={pages.UserEdit}
          componentProps={{ isMe: true, logo, isNewLogo, orgConfig }}
        />
        <PrivateRoute
          path='/user/:email'
          component={pages.UserEdit}
          role={ADMIN}
          componentProps={{ logo, isNewLogo, orgConfig }}
        />
        <PrivateRoute
          path='/hub-configuration'
          component={pages.Configuration}
          role={ADMIN}
          componentProps={{
            color,
            setColor,
            logo,
            setLogo,
            isNewLogo,
            setIsNewLogo,
            orgConfig,
          }}
        />
        <PrivateRoute
          path='/organizations'
          component={pages.Organizations}
          role={CIS_INTERNAL}
          componentProps={{ logo, isNewLogo, orgConfig }}
        />
        <PrivateRoute
          path='/organization/:orgName'
          component={pages.OrganizationEdit}
          role={CIS_INTERNAL}
          componentProps={{ logo, isNewLogo, orgConfig }}
        />
        <PrivateRoute
          path='/risk-messenger'
          component={pages.RiskMessenger}
          role={ADMIN}
          product={RISK_MESSENGER}
          componentProps={{ logo, isNewLogo, orgConfig }}
        />
        <PrivateRoute
          path='/iBank'
          component={pages.IBank}
          role={USER}
          product={IBANK}
          componentProps={{ logo, isNewLogo, orgConfig }}
        />
        <PrivateRoute
          path={['/messaging/template/:template', '/messaging']}
          component={pages.Messaging}
          role={CIS_INTERNAL}
          noScroll
          exact
          componentProps={{ logo, isNewLogo, orgConfig }}
        />
        <PrivateRoute
          path={[
            '/workflow-tables/:acct/:tableId',
            '/workflowtables/:acct/:tableId/:acctName',
          ]}
          component={pages.WorkflowTableDetails}
          role={USER}
          componentProps={{ logo, isNewLogo, orgConfig }}
        />
        <PrivateRoute
          path={[
            '/workflow-tables',
            '/workflow-tables/:acct',
            '/workflowtables/:acct/:acctName',
          ]}
          component={pages.WorkflowTables}
          role={USER}
          componentProps={{ logo, isNewLogo, orgConfig }}
        />
        <Route
          component={pages.NotFound}
          componentProps={{ logo, isNewLogo }}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
