import React, { useEffect } from 'react';
import { Divider, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Formik, useFormikContext } from 'formik';
import HelpIcon from '@mui/icons-material/Help';
import PageTitle from '../../../components/PageTitle';
import IBankAgencySelect from '../../../components/IBankAgencySelect';
import IQCXProductSelect from '../../../components/iQCXProductSelect';
import NameAndDescList from '../../../components/formik/NameAndDescList';
import ToggleAccordion from '../../../components/formik/ToggleAccordion';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '26px',
    fontWeight: '300',
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: '-.5rem',
    color: theme.palette.text.secondary,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  helpIcon: {
    fontSize: '1.25rem',
    marginLeft: '.5rem',
    marginTop: '.25rem',
    color: theme.palette.secondary.main,
  },
}));

export const Products = (props) => {
  const { org, getFormikDetailsValue } = props;
  const classes = useStyles();

  //Get all the props from formik context
  const AutoOrgProductsForm = () => {
    const { values, dirty } = useFormikContext();
    useEffect(() => {
      getFormikDetailsValue(values, dirty);
    }, [values, dirty]);

    return null;
  };

  return (
    <Formik
      initialValues={{
        config: {
          iBank: {
            active: false,
            agencies: [],
          },
          iQCX: {
            active: false,
            accts: [],
          },
          riskMessenger: {
            active: false,
            iQCXAccts: [],
          },
        },
        ...org,
      }}
      enableReinitialize={true}
    >
      {({ values, setFieldValue }) => (
        <Form
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <AutoOrgProductsForm />
          <PageTitle
            title=''
            subtitle="Modify the organization's product access and settings."
          />
          <Divider className={classes.divider} />
          <ToggleAccordion
            name='config.iBank.active'
            value={values?.config?.iBank?.active}
            setFieldValue={setFieldValue}
            label='iBank'
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '1.2rem' }}>Agency Access</span>
              <Tooltip
                title='Define which iBank agencies this organization should have access to.'
                placement='right-end'
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </div>
            <IBankAgencySelect
              multiple={true}
              name='config.iBank.agencies'
              value={
                values?.config?.iBank?.agencies
                  ?.map((_) => (typeof _ === 'object' ? _?.name : _))
                  ?.filter((_) => !!_) || []
              } /* TODO: this weird map/filter logic is to handle pre-existing agency objects in dynamo and can be removed in the future once all agencies in the org config are simple arrays */
              onChange={(e, value) => {
                setFieldValue('config.iBank.agencies', value);
              }}
              style={{ marginTop: '1rem', maxWidth: '850px' }}
            />
          </ToggleAccordion>
          <br />
          <ToggleAccordion
            name='config.iQCX.active'
            value={values?.config?.iQCX?.active}
            setFieldValue={setFieldValue}
            label='iQCX'
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '1.2rem' }}>Account Access</span>
              <Tooltip
                title='Define which iQCX accounts this organization should have access to.'
                placement='right-end'
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </div>
            <IQCXProductSelect
              multiple={true}
              name='config.iQCX.accts'
              value={
                values?.config?.iQCX?.accts
                  ?.map((_) => (typeof _ === 'object' ? _ : _))
                  ?.filter((_) => !!_) || []
              }
              changeAccts={(value) => {
                setFieldValue('config.iQCX.accts', value);
              }}
              style={{ marginTop: '1rem', maxWidth: '850px' }}
            />
          </ToggleAccordion>
          <br />
          <ToggleAccordion
            name='config.riskMessenger.active'
            value={values?.config?.riskMessenger?.active}
            setFieldValue={setFieldValue}
            label='Risk Messenger'
          >
            <NameAndDescList
              name='config.riskMessenger.iQCXAccts'
              value={values?.config?.riskMessenger?.iQCXAccts || []}
              label='Risk Messenger iQCX Account IDs'
              nameLabel='iQCX Account ID'
            />
          </ToggleAccordion>
          <br />
        </Form>
      )}
    </Formik>
  );
};
