import React, { useRef, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { useApiGet } from '../utils/hooks';
import { toast } from 'react-toastify';

export default function IQCXAcctSelect({
  multiple,
  limitTags,
  name,
  onChange,
  style,
  className,
  value,
}) {
  const queryInputRef = useRef(null);
  const {
    isLoading: loading,
    data: iQCXAcctList,
    error,
  } = useApiGet('/hub/iqcx/accts', { fireOnMount: true });
  const nullValue = { label: '', value: '', type: '' };

  // Handle errors from the API Request.
  useEffect(() => {
    if (error) {
      console.log(error);
      toast.error(
        typeof error === 'string' ? error : 'An unexpected error occurred.'
      );
    }
  }, [error]);

  // Create a list of the options to autocomplete to.
  let optionsList = [];
  if (Array.isArray(iQCXAcctList)) {
    optionsList = iQCXAcctList.map((acct) => ({
      label: `${acct.account_name} - ${acct.id}`,
      value: acct.id,
      type: acct.account_type,
    }));
  }
  // This groups shared libraries and customers together.
  optionsList = [
    ...optionsList.filter((f) => f.type === 'Customer'),
    ...optionsList.filter((f) => f.type === 'Shared Library'),
    nullValue,
  ];
  return (
    <Autocomplete
      limitTags={isNaN(limitTags) ? 4 : limitTags}
      name={name}
      multiple={!!multiple}
      options={optionsList || []}
      groupBy={(option) => option.type}
      value={value}
      onChange={onChange}
      filterSelectedOptions={true}
      noOptionsText={
        queryInputRef?.current?.value
          ? 'No accounts match your search'
          : 'Type to search accounts'
      }
      loading={loading}
      className={className}
      style={style}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          label='iQCX Accounts'
          placeholder='Search available iQCX Accounts'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          inputRef={queryInputRef}
        />
      )}
    />
  );
}
