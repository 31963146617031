import React from 'react';
import { useParams } from 'react-router-dom';
import Products from './Products';
import Details from './Details';

export default function Sections(props) {
  const { section } = useParams();
  switch (section) {
    case 'products':
      return <Products {...props} />;
    default:
      return <Details {...props} />;
  }
}
