import { useQuery, useMutation } from 'react-query';
import axios from 'axios';
import { useAuthContext } from '../auth/hooks';

export default function useApiGet(endpoint, config) {
  useAuthContext();

  // Handle query parameters. We perform this manually rather than letting axios do it
  // so that we can include the query params in the react-query key.
  let fullEndpoint = process.env.REACT_APP_INVOKE_URL + endpoint;
  if (config?.params) {
    let queryParams = [];
    for (let param in config.params) {
      queryParams.push(param + '=' + encodeURIComponent(config.params[param]));
    }
    fullEndpoint = `${fullEndpoint}?${queryParams.join('&')}`;
  }

  const query = useQuery(
    fullEndpoint,
    () => {
      return axios.get(fullEndpoint);
    },
    { enabled: config?.fireOnMount || false, refetchOnWindowFocus: false }
  );

  return {
    request: query,
    fire: query.refetch,
    isLoading: query.isLoading,
    isError: query.isError,
    data: query.data?.data,
    error: query.error?.error,
    isFetching: query.isFetching,
  };
}
