import React, { useEffect } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import organizationSchema from '../../../data/schemas/organizationSchema';
import PageTitle from '../../../components/PageTitle';
import TextInput from '../../../components/formik/TextInput';
import Checkbox from '../../../components/formik/Checkbox';
import NameAndDescList from '../../../components/formik/NameAndDescList';
import ToggleAccordion from '../../../components/formik/ToggleAccordion';

const Details = (props) => {
  const { org, getFormikDetailsValue } = props;

  //Get all the props from formik context
  const AutoOrgDetailsForm = () => {
    const { values, dirty } = useFormikContext();
    useEffect(() => {
      getFormikDetailsValue(values, dirty);
    }, [values, dirty]);

    return null;
  };

  return (
    <Formik
      initialValues={{
        name: '',
        displayName: '',
        salesforceAccountNumber: '',
        isActive: false,
        PK: '',
        SK: '',
        config: {
          providers: [],
        },
        ...org,
      }}
      enableReinitialize={true}
      validationSchema={organizationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <AutoOrgDetailsForm />
          <PageTitle
            title=''
            subtitle="Modify the organization's basic settings."
          />
          <Checkbox isSwitch label='Active' name='isActive' />
          <TextInput label='Name' name='name' disabled variant='filled' />
          <TextInput label='Display Name' name='displayName' variant='filled' />
          <TextInput
            label='Salesforce Account Number'
            name='salesforceAccountNumber'
            variant='filled'
          />
          <ToggleAccordion
            name='usesOwnIdp'
            value={values?.usesOwnIdp}
            setFieldValue={setFieldValue}
            label='Uses own IDP'
          >
            <NameAndDescList
              name='config.providers'
              value={values?.config?.providers}
              label='IDP providers'
              nameLabel='Provider'
            />
          </ToggleAccordion>
          <br />
        </Form>
      )}
    </Formik>
  );
};

export default Details;
