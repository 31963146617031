import React from 'react';
import CardList from './CardList';
import OrganizationCard from './OrganizationCard';

export default ({ data }) => {
  return (
    <CardList
      columns={[
        {
          Header: 'ID', //have to provide something even though we aren't displaying headers to appease react-table (if not passed as a prop and instead is defined in the TableBase component it causes a stack overflow with useEffect since the reference is non-unique... any solutions?)
        },
      ]}
      data={data}
    >
      {(page, prepareRow) => {
        return page.map((row) => {
          prepareRow(row);
          return <OrganizationCard key={row.id} organization={row.original} />;
        });
      }}
    </CardList>
  );
};
