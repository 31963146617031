import React, { useEffect, useState } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRouteMatch } from 'react-router';
import { toast } from 'react-toastify';
import RecordsAddedIcon from '../../../assets/icons/RecordsAdded';
import RecordsCurrentUserTableIcon from '../../../assets/icons/RecordsCurrentUserTable';
import RecordsDeletedIcon from '../../../assets/icons/RecordsDeleted';
import RecordsNotValid from '../../../assets/icons/RecordsNotValid';
import RecordsUpdatedIcon from '../../../assets/icons/recordsUpdated';
import RecordsUploadedIcon from '../../../assets/icons/RecordsUploaded';
import Loader from '../../../components/Loader';
import { useApiPost, useApiGet } from '../../../utils/hooks';

const useStyles = makeStyles((theme) => ({
  preview: {
    backgroundColor: theme.palette.primary.cisBlueLight,
    padding: '16px',
    margin: '0 16px',
    width: '50%',
    textAlign: 'center',
    minWidth: '480px',
    minHeight: '200px',
  },
  iconWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: '40px',
    width: '40px',
    marginRight: '8px',
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '25px',
  },
  errorBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  errorText: {
    textAlign: 'left',
  },
}));

function UploadPreview({
  detailsRequest,
  fileTooLarge,
  previewData,
  rows,
  mode,
  clearFile,
  setOpenValidationDetails,
  setLockedNavigation,
  setPreviewOpen,
}) {
  const classes = useStyles();
  const {
    params: { id, acct },
  } = useRouteMatch('/workflow-tables/:acct/:id') || {
    params: { id: '', acct: '' },
  };

  const [activeView, setActiveView] = useState('validations');
  const [loading, setLoading] = useState(false);
  const [intervalLoading, setIntervalLoading] = useState(false);

  const { fire: refreshData, data } = detailsRequest;
  const {
    fire: postUpload,
    data: uploadData,
    isError,
    isLoading,
    request: postDataRequest,
  } = useApiPost(`/workflow-tables/data/${acct}/${id}`);
  const { fire: sendNotification } = useApiPost(
    `/workflow-tables/send/${acct}/${id}`
  );
  const {
    fire: pingDynamo,
    data: pingData,
    isFetching: pingDataIsLoading,
  } = useApiGet(`/workflow-tables/${acct}/${id}/ping`, {
    params: { pk: previewData.dynamoPK, sk: previewData.dynamoSK },
  });

  useEffect(() => {
    if (!isLoading && isError) {
      toast.error(
        'Something went wrong while uploading! No changes have been committed.'
      );
      // Unlock the UI.
      setLockedNavigation(false);
      setPreviewOpen(false);
    }
    if (pingData?.errorProcessing) {
      toast.error(pingData.errorProcessing);
    }

    if (
      !isLoading &&
      postDataRequest.isSuccess &&
      data.notifyEmailsStatus &&
      data.notifyEmails.length
    ) {
      toast.success('File upload completed Successfully.');
      sendNotification({ data, uploadData, rows });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // Repeatedly ping dynamo for updates on workflow upload.
  useEffect(() => {
    if (pingDataIsLoading || !pingData) {
      return;
    }
    if (pingData.completionPercentage >= 100) {
      setIntervalLoading(false);
      setLoading(false);
      return;
    }
    setTimeout(pingDynamo, 3000);
  }, [pingDataIsLoading]);

  // Upload new data, lock navigation, and setLoading.
  function finalizeUpload() {
    postUpload({
      fileRows: rows,
      mode: mode,
      dynamoPK: previewData.dynamoPK,
      dynamoSK: previewData.dynamoSK,
    });
    setActiveView('update');
    setLockedNavigation(true);
    if (rows.length > 300) setIntervalLoading(true);
    else setLoading(true);
    pingDynamo();
  }

  // Unlock navigation, refresh data, and close popup.
  async function finish() {
    setLockedNavigation(false);
    setLoading(true);
    await refreshData();
    setPreviewOpen(false);
  }

  if (loading) {
    return (
      <div className={classes.preview}>
        <Loader />
      </div>
    );
  } else if (intervalLoading) {
    return (
      <div className={classes.preview}>
        {pingData?.completionPercentage > 0 ? (
          <Loader
            variant='determinate'
            value={pingData?.completionPercentage || 0}
          ></Loader>
        ) : (
          <Loader />
        )}
      </div>
    );
  } else if (fileTooLarge) {
    <div className={classes.preview}>
      <div className={classes.errorBox}>
        <div>
          <h2>Table Too Large</h2>
          <div className={classes.iconWrap}>
            <WarningAmberIcon color='secondary' className={classes.icon} />
            <span className={classes.errorText}>
              File exceeds 10k rows. File upload length beyond 10k rows is not
              currently supported.
            </span>
          </div>
        </div>
        <div className={classes.buttonWrap}>
          <Button
            color='error'
            style={{ marginRight: '8px' }}
            onClick={clearFile}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>;
  } else if (activeView === 'validations') {
    return (
      <div className={classes.preview}>
        <h2>Upload Preview</h2>
        <div className={classes.iconWrap}>
          <RecordsCurrentUserTableIcon className={classes.icon} />
          <span>
            User table currently has {data?.tableData?.length} records
          </span>
        </div>
        <div className={classes.iconWrap}>
          <RecordsUploadedIcon className={classes.icon} />
          <span>The uploaded file contains {rows?.length} records</span>
        </div>
        <div className={classes.iconWrap}>
          <RecordsAddedIcon className={classes.icon} />
          <span>{previewData.recordsAdded} records will be added</span>
        </div>
        <div className={classes.iconWrap}>
          <RecordsDeletedIcon className={classes.icon} />
          <span>{previewData.recordsDeleted} records will be deleted</span>
        </div>
        <div className={classes.iconWrap}>
          <RecordsUpdatedIcon className={classes.icon} />
          <span>{previewData.recordsModified} records will be modified</span>
        </div>
        <div className={classes.iconWrap}>
          <RecordsNotValid className={classes.icon} />
          <span>
            {previewData?.validationErrors?.length} records failed validation
          </span>
          {previewData?.systemErrors?.length > 0 && (
            <p>
              {previewData.systemErrors.length} problems occurred during
              validation
            </p>
          )}
        </div>
        <div className={classes.buttonWrap}>
          <Button
            color='error'
            style={{ marginRight: '8px' }}
            onClick={clearFile}
          >
            Cancel
          </Button>
          {previewData?.validationErrors?.length > 0 ? (
            <Button
              variant='contained'
              onClick={() => setOpenValidationDetails(true)}
            >
              Check Validation Errors
            </Button>
          ) : (
            <Button variant='contained' onClick={finalizeUpload}>
              Finalize Upload
            </Button>
          )}
        </div>
      </div>
    );
  } else if (activeView === 'update') {
    return (
      <div className={classes.preview}>
        <h2>Table Updated</h2>
        <div className={classes.iconWrap}>
          <RecordsCurrentUserTableIcon className={classes.icon} />
          <span>User table now has {data?.tableData?.length} records</span>
        </div>
        <div className={classes.iconWrap}>
          <RecordsUploadedIcon className={classes.icon} />
          <span>The uploaded file contained {rows?.length} records</span>
        </div>
        <div className={classes.iconWrap}>
          <RecordsAddedIcon className={classes.icon} />
          <span>{previewData.recordsAdded} records were added</span>
        </div>
        <div className={classes.iconWrap}>
          <RecordsDeletedIcon className={classes.icon} />
          <span>{previewData.recordsDeleted} records were deleted</span>
        </div>
        <div className={classes.buttonWrap}>
          <Button variant='contained' color='success' onClick={finish}>
            Finish
          </Button>
        </div>
      </div>
    );
  } else {
    return <div className={classes.preview} />;
  }
}
export default UploadPreview;
