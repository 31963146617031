import React, { memo } from 'react';
import CardList from './CardList';
import PersonCard from './PersonCard';
import { makeStyles } from '@mui/styles';
import { useAuthContext } from '../../utils/auth/hooks';

const useStyles = makeStyles((theme) => ({
  notFoundWrapper: {
    fontSize: '1.25rem',
    textAlign: 'center',
    padding: '2rem',
  },
}));

export default memo(({ data, setLoading }) => {
  const classes = useStyles();
  const { user: signedInUser } = useAuthContext();

  return (
    <CardList
      columns={[
        {
          Header: 'ID', //have to provide something even though we aren't displaying headers to appease react-table (if not passed as a prop and instead is defined in the TableBase component it causes a stack overflow with useEffect since the reference is non-unique... any solutions?)
        },
      ]}
      data={data}
    >
      {(page, prepareRow) => {
        const rows = page
          .map((row) => {
            prepareRow(row);
            // this filters out the current user from the list
            return signedInUser?.email === row?.original?.email ? null : (
              <PersonCard
                key={row.id}
                person={row.original}
                setLoading={setLoading}
              />
            );
          })
          .filter((i) => !!i);

        //TODO: should probably dress up the not found message here and in other places with some nice vectors
        return rows.length ? (
          rows
        ) : (
          <div className={classes.notFoundWrapper}>No users found</div>
        );
      }}
    </CardList>
  );
});
