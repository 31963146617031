import React, { useEffect } from 'react';
import { Formik, FastField, Form, useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  Divider,
  FormControl,
  MenuItem,
  Button,
  Tooltip,
  Chip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Select } from 'formik-mui';
import { ROLE_OPTIONS } from '../../../utils/auth/constants';
import UserStatus from './UserStatus';
import updateUserDetailsSchema from '../../../data/schemas/updateUserDetailsSchema';
import TextInput from '../../../components/formik/TextInput';
import shouldComponentUpdate from '../../../components/formik/shouldComponentUpdate';
import { CIS_INTERNAL } from '../../../utils/auth/constants';
import { useAuthContext } from '../../../utils/auth/hooks';
import ImpersonateIcon from '@mui/icons-material/SupervisedUserCircle';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '26px',
    fontWeight: '300',
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: '-.5rem',
    color: theme.palette.text.secondary,
    minWidth: `75%`,
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  label: {
    background: theme.palette.background.default,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  section: {
    display: `flex`,
  },
  impersonate: {},
  ownidp: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginRight: '0.5rem',
  },
  idpChip: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(1),
  },
}));

export default function Details({
  user,
  getFormikDetailsValue,
  isMe,
  initialUserDisplayed,
}) {
  const classes = useStyles();
  const history = useHistory();

  const { roleIsAtLeast, activateImpersonateUser } = useAuthContext();

  // Get all the props from formik context
  const AutoDetailsForm = () => {
    const { values, dirty } = useFormikContext();
    useEffect(() => {
      getFormikDetailsValue(values, dirty);
    }, [values, dirty]);

    return null;
  };

  const handleImpersonateUserClick = () => {
    activateImpersonateUser(user);
    history.push('/');
  };

  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        role: '',
        ...user,
      }}
      validationSchema={updateUserDetailsSchema}
      enableReinitialize={true}
    >
      {() => (
        <Form>
          <AutoDetailsForm />
          <div className={classes.section}>
            <p className={classes.subtitle}>
              Below {isMe ? 'you' : "the user's"} can modify your basic account
              details and information.
            </p>
            <div className={classes.ownidp}>
              {user?.useExternal && (
                <Tooltip title={user?.provider}>
                  <Chip
                    label='Uses own IDP'
                    variant='outlined'
                    size='small'
                    className={classes.idpChip}
                  />
                </Tooltip>
              )}
            </div>
            <div className={classes.impersonate}>
              {!isMe && roleIsAtLeast(CIS_INTERNAL) && (
                <Button
                  startIcon={<ImpersonateIcon />}
                  onClick={handleImpersonateUserClick}
                  disabled={!user?.email}
                >
                  Impersonate
                </Button>
              )}
            </div>
          </div>
          <br />
          <TextInput
            label='Email Address'
            name='email'
            type='email'
            variant='filled'
            disabled
          />
          <TextInput
            label='First Name'
            name='firstName'
            variant='filled'
            disabled={initialUserDisplayed}
          />
          <TextInput
            label='Last Name'
            name='lastName'
            variant='filled'
            disabled={initialUserDisplayed}
          />
          <Divider className={classes.divider} />
          <FormControl variant='standard' className={classes.formControl}>
            <FastField
              component={Select}
              labelId='role-label'
              label='Role'
              type='text'
              name='role'
              fullWidth
              variant='standard'
              disabled={isMe || initialUserDisplayed}
              shouldUpdate={shouldComponentUpdate}
            >
              {ROLE_OPTIONS?.map((option) => (
                <MenuItem value={option?.value} key={option?.value}>
                  {option?.label}
                </MenuItem>
              ))}
            </FastField>
            <br />
            {!isMe && user?.enabled !== undefined && <UserStatus user={user} />}
          </FormControl>
          <Divider className={classes.divider} />
        </Form>
      )}
    </Formik>
  );
}
