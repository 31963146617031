import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRouteMatch, useLocation } from 'react-router-dom';

import Overview from './Overview';
import Schema from './Schema';
import Upload from './Upload';
import TableViewer from './TableViewer';
import Notifications from './Notifications';
import RootContainer from '../../components/RootContainer';
import BreadcrumbsSaveActionBar from '../../components/BreadcrumbsSaveActionBar';
import Loader from '../../components/Loader';
import TooltipTab from '../Messaging/components/TooltipTab';
import PageTitle from '../../components/PageTitle';
import { useApiGet, useApiPut } from '../../utils/hooks';
import { useAuthContext } from '../../utils/auth/hooks';

const useStyles = makeStyles((theme) => ({
  tabs: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '32px',
  },
  main: {
    backgroundColor: theme.palette.white.main,
  },
  tabPanelBox: {
    padding: '32px',
  },
  tabPanel: {
    paddingBottom: theme.spacing(1),
  },
  tabsWrap: {
    justifyContent: 'space-between',
  },
}));

export const WorkflowTableDetails = () => {
  // This is the browser native way to access a query parameter.
  let initialTab = new URLSearchParams(useLocation().search).get('tab');
  const [tabValue, setTabValue] = useState(initialTab || 'overview');
  const [lockedNavigation, setLockedNavigation] = useState(false);
  const [tablePermissions, setTablePermissions] = useState({});
  const [dirtyForm, setDirtyForm] = useState(false);
  const [formikValues, setFormikValues] = useState({});
  const classes = useStyles();
  const { user } = useAuthContext();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const {
    params: { id, acct, acctName },
  } = useRouteMatch('/workflow-tables/:acct/:id/:acctName');

  // Get initial row data.
  const detailsRequest = useApiGet(`/workflow-tables/${acct}/${id}/0/10000`, {
    fireOnMount: true,
  });
  const { data, fire: refreshData, isLoading: loading } = detailsRequest;

  const {
    fireAsync: makePutRequest,
    error,
    data: putData,
    isLoading: putLoading,
  } = useApiPut(`/workflow-tables/${acct}/${id}`);

  const xsViewport = useMediaQuery('(min-width:0px)');
  const smViewport = useMediaQuery(
    '(min-width:600px) and (orientation: portrait), (min-height:550px) and (orientation: landscape)'
  );
  const mdViewport = useMediaQuery('(min-width:900px)');

  const setPerms = (table) => {
    let copied = { ...table };
    if (copied.libraryType === null) copied.libraryType = 'local';
    setTablePermissions(getPermissions(user, copied, acct));
  };

  const tableName = data?.name || 'loading...';
  const hideButton = tabValue === 'table_viewer' || tabValue === 'upload';

  async function handleFormikSubmit() {
    setDirtyForm(false);
    await makePutRequest(formikValues);
    refreshData();
  }

  // Error notification if get request fails.
  useEffect(() => {
    if (detailsRequest.error) {
      toast.error(detailsRequest.error);
    }
  }, [detailsRequest.error]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    } else if (putData) {
      toast.success('Successfully updated Workflow configuration!');
    }
  }, [error, putData]);

  useEffect(() => {
    const tab = query.get('tab');
    const smTargets = ['overview', 'notifications'];
    if (!smViewport && !smTargets.includes(tab)) setTabValue('overview');
  }, []);

  useEffect(() => {
    if (!loading && data) {
      setTablePermissions(data.permissions);
    }
  }, [loading]);

  const formikInitialValues = {
    name: data?.name,
    desc: data?.desc,
    locked: data?.locked,
    externalSync: data?.externalSync,
    allowUserUpload: data?.allowUserUpload,
    allowResruleEdit: data?.allowResruleEdit,
    allowUserEdit: data?.allowUserEdit,
    internalUserOnly: data?.internalOnly,
    tableType: data?.tableType,
    functionalPoint: data?.functionalPoint,
    category: data?.category,
    libraryType: data?.libraryType,
    schema: data?.schema,
    notifyEmails: data?.notifyEmails,
    notifyEmailsStatus: data?.notifyEmailsStatus
      ? data?.notifyEmailsStatus
      : '',
  };

  if (loading) {
    return (
      <RootContainer>
        <Loader />
      </RootContainer>
    );
  }

  return (
    <RootContainer>
      <PageTitle title={tableName} />
      <BreadcrumbsSaveActionBar
        crumbsTrail={{
          Workflow: '/workflow-tables',
          Tables: '/workflow-tables',
          [`${acctName}`]: `/workflowtables/${acct}/${acctName}`,
          [`${tableName} - ${id}`]: null,
        }}
        isDirty={dirtyForm}
        hideButton={hideButton}
        onSubmit={handleFormikSubmit}
        isSubmitting={putLoading}
      />
      <br />
      <main className={classes.main}>
        <Tabs
          value={tabValue}
          scrollButtons={xsViewport || smViewport || mdViewport ? true : 'auto'}
          allowScrollButtonsMobile={true}
          className={classes.tabs}
          variant={
            xsViewport || smViewport || mdViewport ? 'scrollable' : 'fullWidth'
          }
          classes={
            xsViewport || smViewport || mdViewport
              ? { flexContainer: classes.tabsWrap }
              : {}
          }
          onChange={(_, target) => {
            setTabValue(target);
          }}
          indicatorColor='primary'
        >
          <CustomTab
            value='overview'
            tablePermissions={tablePermissions}
            lockedNavigation={lockedNavigation}
            disabledMessage=''
          ></CustomTab>
          {/* Don't render schema, upload, and table viewer if on mobile. */}
          {smViewport && (
            <CustomTab
              value='schema'
              tablePermissions={tablePermissions}
              lockedNavigation={lockedNavigation}
              disabledMessage=''
            />
          )}
          {smViewport && (
            <CustomTab
              value='upload'
              tablePermissions={tablePermissions}
              lockedNavigation={lockedNavigation}
              disabledMessage='Library accounts cannot upload table data. Please choose a customer account to perform an upload.'
            />
          )}
          {smViewport && (
            <CustomTab
              value='table_viewer'
              tablePermissions={tablePermissions}
              lockedNavigation={lockedNavigation}
              disabledMessage='To see the data of a library table please access from the customer account.'
            />
          )}
          <CustomTab
            value='notifications'
            tablePermissions={tablePermissions}
            lockedNavigation={lockedNavigation}
            disabledMessage='Library accounts cannot upload table data. Notifications will not be used.'
          ></CustomTab>
        </Tabs>
        <Formik initialValues={formikInitialValues} enableReinitialize>
          {(formikProps) => {
            // Runs every time formik is updated.
            setDirtyForm(formikProps.dirty);
            setFormikValues(formikProps.values);
            return (
              <div className={classes.tabPanelBox}>
                <TabPanel
                  currentTab={tabValue}
                  detailsRequest={detailsRequest}
                  formikProps={formikProps}
                  setLockedNavigation={setLockedNavigation}
                  tablePermissions={tablePermissions}
                />
                <br />
              </div>
            );
          }}
        </Formik>
      </main>
    </RootContainer>
  );
};

const TabPanel = ({
  currentTab,
  detailsRequest,
  formikProps,
  setLockedNavigation,
  tablePermissions,
}) => {
  switch (currentTab) {
    case 'overview':
      return (
        <Overview
          detailsRequest={detailsRequest}
          formikProps={formikProps}
          access={tablePermissions?.overview}
        />
      );
    case 'schema':
      return (
        <Schema
          detailsRequest={detailsRequest}
          formikProps={formikProps}
          access={tablePermissions?.schema}
        />
      );
    case 'upload':
      return (
        <Upload
          detailsRequest={detailsRequest}
          setLockedNavigation={setLockedNavigation}
          access={tablePermissions?.upload}
        />
      );
    case 'table_viewer':
      return (
        <TableViewer
          detailsRequest={detailsRequest}
          access={tablePermissions?.table_viewer}
        />
      );
    case 'notifications':
      return (
        <Notifications
          detailsRequest={detailsRequest}
          access={tablePermissions?.notifications}
        />
      );
    default:
      return null;
  }
};

const CustomTab = ({
  value,
  tablePermissions,
  lockedNavigation,
  disabledMessage,
  ...props
}) => {
  const tabTitle = value === 'schema' ? 'columns' : value.replace('_', ' ');
  if (tablePermissions?.[value]) {
    return (
      <Tab
        value={value}
        label={tabTitle}
        disabled={lockedNavigation}
        {...props}
      />
    );
  } else {
    return (
      <TooltipTab
        value={value}
        tooltip={disabledMessage}
        label={tabTitle}
        disabled
        {...props}
      />
    );
  }
};
