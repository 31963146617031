import React, { useEffect, useState, useCallback } from 'react';
import { useApiGet } from '../../utils/hooks';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import RootContainer from '../../components/RootContainer.js';
import Breadcrumbs from '../../components/Breadcrumbs';
import CardList from '../../components/Table/CardList';
import { Card, IconButton, Tooltip, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Title from '../../components/Title';
import EditIcon from '@mui/icons-material/Create';
import UploadIcon from '@mui/icons-material/Upload';
import UploadNotAllowedIcon from '../../assets/icons/UploadDisabled';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IQCXAcctSelect from '../../components/iQCXAcctSelect';
import IQCXAcctSelectAdmin from '../../components/iQCXAcctSelectAdmin';
import Fuse from 'fuse.js';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterBy, { DEFAULT_FILTER_VALUES, SELECT_BOXES } from './FilterBy';
import { useAuthContext } from '../../utils/auth/hooks';
import { useIsMobileDevice } from '../../utils/hooks';
import { CIS_INTERNAL } from '../../utils/auth/constants';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
    marginLeft: '0px',
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    margin: '8px',
    color: 'rgba(0,0,0,0.56)',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    background: theme.palette.background.default,
    padding: theme.spacing(1),
    borderRadius: '4px',
  },
  searchWrapper: {
    display: 'flex',
    width: '100%',
    '& > div:only-child': {
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    '& > div:first-of-type': {
      width: '50%',
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        width: '41.66%',
      },
    },
    '& > div:last-of-type': {
      width: '50%',
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        width: '33.33%',
      },
    },
    justifyContent: 'space-between',
  },
  buttonWrapperRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '.5rem 0',
  },
  chip: {
    width: '100%',
    paddingTop: 2,
    color: theme.palette.neutrals.medium,
  },
}));

export const WorkflowTables = (props) => {
  const { orgConfig } = props;
  const [searchInputValue, setSearchInputValue] = useState('');
  const match = useRouteMatch('/workflowtables/:acct/:acctName');
  const urlAcct = match?.params?.acct;
  const [acct, setAcct] = useState(urlAcct);
  const urlAcctName = match?.params?.acctName;
  const [acctName, setAcctName] = useState(urlAcctName);
  const {
    isLoading: loading,
    error,
    data,
  } = useApiGet(`/workflow-tables/${acct}`, { fireOnMount: !!acct });
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useIsMobileDevice();
  const [filters, setFilters] = useState(DEFAULT_FILTER_VALUES);
  const { user, roleIsAtLeast } = useAuthContext();

  useEffect(() => {
    if (error) {
      toast.error(error);
    }

    if (orgConfig?.iQCX?.active && (orgConfig?.iQCX?.accts).length == 1) {
      setAcct(orgConfig?.iQCX?.accts[0]?.value);
      history.push(`/workflow-tables/${orgConfig?.iQCX?.accts[0]?.value}`);
    }
  }, [error]);

  function changeAcct(_, newValue) {
    if (newValue && newValue.value) {
      setAcct(newValue.value);
      setAcctName(newValue.label);
      history.push(`/workflowtables/${newValue.value}/${newValue.label}`);
    }
  }

  const filterData = useCallback(() => {
    if (!data || data.fail) {
      return data || [];
    }

    // handle data filtering
    const finalResult = data.filter((f) => {
      // check if locked filter is on
      if (filters.locked) {
        if (f.locked !== 1) {
          return false;
        }
      }
      // check if interal use filter is on
      if (filters.internal_use_only) {
        if (f.internal_use_only !== 1) {
          return false;
        }
      }

      if (filters.allow_user_upload) {
        if (f.allow_user_upload !== 1) {
          return false;
        }
      }

      if (filters.external_sync) {
        if (f.external_sync !== 1) {
          return false;
        }
      }

      if (filters.allow_resrule_edit) {
        if (f.allow_resrule_edit !== 1) {
          return false;
        }
      }

      if (filters.table_type) {
        if (f.table_type !== filters.table_type) {
          return false;
        }
      }

      if (filters.functional_point) {
        if (f.functional_point !== filters.functional_point) {
          return false;
        }
      }

      if (filters.libraryType) {
        if (f.libraryType !== filters.libraryType) {
          return false;
        }
      }

      return true;
    });

    if (searchInputValue.length) {
      const options = {
        threshold: 0.4,
        keys: ['user_table_name', 'id'],
      };
      const fuse = new Fuse(finalResult, options);
      const filtered = fuse.search(searchInputValue).map((res) => ({
        ...res.item,
        matches: res.matches,
      }));
      return filtered;
    }

    return finalResult;
  }, [data, filters, searchInputValue]);

  const trail = {
    Workflow: null,
    Tables: '/workflow-tables',
  };
  if (acct) {
    trail[`${acctName}`] = `/workflow-tables/${acct}`;
  }

  const translateLibTypeToLabel = (type, accountName) => {
    let libLabel = SELECT_BOXES.libraryType.options.find(
      (t) => t.value === type
    )?.label;
    if (libLabel === '') libLabel = 'Local';
    return `${libLabel} - ${accountName}`;
  };

  const UploadButton = ({ account, table }) => {
    //It won't show any upload related icon in mobile
    if (isMobile) {
      return <></>;
    }
    const isUpload = table?.permissions?.upload;
    return (
      <Tooltip title={isUpload ? 'Upload allowed' : 'Upload not allowed'}>
        {isUpload ? (
          <IconButton
            onClick={() =>
              history.push(`/workflow-tables/${account}/${table.id}?tab=upload`)
            }
          >
            <UploadIcon />
          </IconButton>
        ) : (
          <span>
            <UploadNotAllowedIcon />
          </span>
        )}
      </Tooltip>
    );
  };

  return (
    <RootContainer>
      <Breadcrumbs trail={trail} />
      <div className={classes.container}>
        <div className={classes.searchWrapper}>
          {roleIsAtLeast(CIS_INTERNAL) ? (
            <IQCXAcctSelect
              name='account-select'
              value={acct}
              onChange={changeAcct}
            />
          ) : (
            <IQCXAcctSelectAdmin
              name='account-select'
              value={acct}
              onChange={changeAcct}
              orgConfig={orgConfig}
            />
          )}
          <TextField
            label='Search Tables'
            variant='outlined'
            value={searchInputValue}
            onChange={(e) => setSearchInputValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <FilterBy filters={filters} setFilters={setFilters} />
        </div>
      </div>
      {!acct ? (
        <p>Please select an account</p>
      ) : loading ? (
        <Loader />
      ) : (
        <CardList columns={[{ Header: 'ID' }]} data={filterData()}>
          {(page, prepareRow) => {
            const rows = page.map((row) => {
              prepareRow(row);
              const t = row.original;
              return (
                <Card key={t.id} className={classes.card}>
                  <span>
                    <Title
                      title={`${t.user_table_name} - ${t.id}`}
                      subtitle={t.user_table_desc}
                    />
                  </span>

                  <span className={classes.iconWrapper}>
                    <Tooltip title={`Acct ID: ${t.cis_account_id}`}>
                      <Chip
                        label={translateLibTypeToLabel(
                          t.libraryType,
                          t.account_name
                        )}
                        className={classes.chip}
                        variant='outlined'
                        sx={{ color: 'neutrals.medium', bgColor: '#FFFFFF' }}
                      />
                    </Tooltip>
                    {t.internal_use_only ? (
                      <Tooltip title='Internal use only'>
                        <VisibilityOffIcon />
                      </Tooltip>
                    ) : null}
                    <UploadButton account={acct} table={t} />
                    <Tooltip title='Edit table'>
                      <IconButton
                        aria-label='Edit'
                        onClick={() =>
                          history.push(
                            `/workflow-tables/${acct}/${t.id}/${acctName}?tab=overview`
                          )
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                </Card>
              );
            });

            return rows.length ? rows : <p>No user tables</p>;
          }}
        </CardList>
      )}
    </RootContainer>
  );
};

export const determineChipColor = (type) => {
  switch (type) {
    case 'restricted':
      return 'error';
    case 'locked_definition':
      return 'primary';
    case 'locked_key':
      return 'secondary';
    case 'local':
    default:
      return undefined;
  }
};
