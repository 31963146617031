import React from 'react';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { Grid, Chip } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Breadcrumbs from '../components/Breadcrumbs';
import { PriorityHigh } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  saveButton: {
    marginLeft: '10px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '45px',
  },
  breadcrumb: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const BreadcrumbsSaveActionBar = (props) => {
  const {
    crumbsTrail,
    onSubmit,
    handleUserDelete,
    isMe,
    isDeleting,
    isSubmitting,
    hideButton,
    deleteButton,
    isDirty,
  } = props;
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item xs='auto' className={classes.breadcrumb}>
        <Breadcrumbs trail={crumbsTrail} />
      </Grid>
      <Grid item xs='auto'>
        {!hideButton && (
          <Chip
            variant='outlined'
            icon={<PriorityHigh />}
            color='error'
            hidden={!isDirty}
            label='Unsaved Changes'
          ></Chip>
        )}
        {isMe && deleteButton && (
          <LoadingButton
            className={classes.saveButton}
            variant='contained'
            color='primary'
            size='large'
            onClick={handleUserDelete}
            loading={isDeleting}
            loadingPosition='start'
            startIcon={<DeleteForeverIcon />}
          >
            {isDeleting ? 'Deleting' : 'Delete'}
          </LoadingButton>
        )}

        {!hideButton && (
          <LoadingButton
            className={classes.saveButton}
            variant='contained'
            color='primary'
            size='large'
            onClick={onSubmit}
            loading={isSubmitting}
            loadingPosition='start'
            disabled={isDeleting}
            startIcon={
              <span /> /* have to provide this empty span to get rid of a warning */
            }
          >
            {isSubmitting ? 'Saving' : 'Save'}
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  );
};

export default BreadcrumbsSaveActionBar;
