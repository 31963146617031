import React, { useEffect, useState } from 'react';
import {
  useRouteMatch,
  useHistory,
  useLocation,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Sections from './Sections';
import { toast } from 'react-toastify';
import SectionNavigation from '../../components/SectionNavigation';
import Loader from '../../components/Loader';
import ErrorScreen from '../../components/ErrorScreen';
import { useAuthContext } from '../../utils/auth/hooks';
import RootContainer from '../../components/RootContainer.js';
import { useApiGet, useApiPut, useApiDelete } from '../../utils/hooks';
import BreadcrumbsSaveActionBar from '../../components/BreadcrumbsSaveActionBar';
import PageTitle from '../../components/PageTitle';

export const UserEdit = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [userToDisplay, setUserToDisplay] = useState({});
  const [isTab, setIsTab] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [formikValue, setFormikValue] = useState({});

  const {
    path,
    url,
    params: { email },
  } = useRouteMatch('/user/:email');
  const { user: signedInUser } = useAuthContext();
  const { isMe = email.toLowerCase() === 'me' } = props;
  const userEmail = isMe ? signedInUser?.email : decodeURIComponent(email);

  useEffect(() => {
    //redirect to /me route if the user is viewing themself
    if (
      email.toLowerCase() !== 'me' &&
      signedInUser?.email === decodeURIComponent(email)
    ) {
      history.push(location?.pathname?.replace(email, 'me'));
    }
  });

  const {
    isLoading: loading,
    error: getError,
    data: apiUser,
    fire: refreshUser,
  } = useApiGet(`/users/${isMe ? 'me' : encodeURIComponent(userEmail)}`, {
    fireOnMount: true,
  });

  const {
    fire: updateUser,
    error: putError,
    isLoading: putLoading,
    request: requestUpdate,
  } = useApiPut(`/users/${encodeURIComponent(userToDisplay?.email)}`);

  const {
    fire: deleteUser,
    error: deleteError,
    isLoading: deleteLoading,
    request: requestDelete,
  } = useApiDelete(`/users/${encodeURIComponent(userToDisplay?.email)}/delete`);

  const locationUser = props?.location?.state?.initialUser;

  useEffect(() => {
    if (apiUser?.email === userEmail) {
      /*
        only use apiUser if it is the current result of the user we want to display...
        if you are viewing a user and click the "Account Settings" link in the navigation the component doesn't unmount so apiUser is already populated with the other users details, that is why we need to compare the email values
      */
      setUserToDisplay(apiUser);
    } else if (isMe) {
      setUserToDisplay(signedInUser);
    } else if (locationUser) {
      setUserToDisplay(locationUser);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUser, isMe, locationUser]);
  //have to get this separate to keep the path and url sans section for the nested section router below
  const sectionMatch = useRouteMatch('/user/:email/:section');
  const section = sectionMatch?.params?.section;
  useEffect(() => {
    if (putError || deleteError) {
      toast.error(
        typeof putError === 'string'
          ? putError
          : 'An unexpected error occurred.'
      );
    }
    if (requestUpdate.isSuccess) {
      toast.success('User successfully updated.');
    }
    if (requestDelete.isSuccess) {
      toast.success('User successfully deleted.');
      history.push('/users');
    }
    deleteError || requestDelete.isSuccess;
  }, [
    deleteError,
    putError,
    history,
    requestDelete.isSuccess,
    requestUpdate.isSuccess,
  ]);

  let crumbsTrail = {};
  if (!isMe) {
    crumbsTrail['Admin'] = null;
  }
  crumbsTrail['Users'] = '/users';
  crumbsTrail[
    userToDisplay?.email
      ? `${userToDisplay.firstName} ${userToDisplay.lastName}`
      : 'N/A'
  ] = url;

  if (section) {
    let sectionTitle = 'N/A';
    //eslint-disable-next-line default-case
    switch (section) {
      case 'details':
        sectionTitle = 'Profile';
        break;
      case 'products':
        sectionTitle = 'Products';
        break;
    }
    crumbsTrail[sectionTitle] = `${url}/${section}`;
  }

  // callback function called everytime when any trigger happend in formik
  const getFormikDetailsValue = (value, dirty) => {
    setIsDirty(dirty);
    setFormikValue(value);
  };

  // retain the state value while switching tab
  const sectionTabClicked = () => {
    setUserToDisplay(formikValue);
    if (isDirty) {
      setIsTab(true);
    }
  };

  // trigger when submit button get called
  const handleSubmit = async () => {
    if (initialUserDisplayed) {
      return;
    }
    setIsTab(false);
    setUserToDisplay(formikValue);
    await updateUser(formikValue);
    await refreshUser();
  };

  const initialUserDisplayed = loading && !!userToDisplay?.email;

  const handleUserDelete = async () => {
    await deleteUser({ email: userToDisplay?.email });
  };

  return (
    <RootContainer>
      <PageTitle title='Edit User' />
      <BreadcrumbsSaveActionBar
        crumbsTrail={crumbsTrail}
        onSubmit={handleSubmit}
        handleUserDelete={handleUserDelete}
        isDeleting={deleteLoading}
        deleteButton={section === 'details' ? true : false}
        isMe={isMe}
        isDirty={isDirty || isTab}
        isSubmitting={putLoading}
      />
      <div>
        <SectionNavigation
          sectionTabClicked={sectionTabClicked}
          sections={[
            {
              text: 'Profile',
              label: 'details',
              url: `${url}/details`,
            },
            {
              text: 'Product Access',
              label: 'products',
              url: `${url}/products`,
            },
          ]}
        />
        <div>
          {loading && !userToDisplay?.email ? (
            <Loader />
          ) : !userEmail || (getError && getError !== 'TOKEN FAILURE') ? (
            <ErrorScreen
              title='Not Found'
              code='404'
              message='The user you were looking for does not exist'
              fontSize='.75rem'
            />
          ) : (
            <Switch>
              <Route path={`${path}/:section`}>
                <Sections
                  user={userToDisplay}
                  isMe={isMe}
                  getFormikDetailsValue={getFormikDetailsValue}
                  initialUserDisplayed={initialUserDisplayed}
                />
              </Route>
              <Redirect to={`${url}/details`} />
            </Switch>
          )}
        </div>
      </div>
    </RootContainer>
  );
};
