import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  useParams,
  useRouteMatch,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Details from './Sections/Details';
import { Products } from './Sections/Products';
import { useApiPut } from '../../utils/hooks';
import useGetOrgByName from '../../utils/hooks/useGetOrgByName';
import PageTitle from '../../components/PageTitle';
import Loader from '../../components/Loader';
import SectionNavigation from '../../components/SectionNavigation';
import ErrorScreen from '../../components/ErrorScreen';
import RootContainer from '../../components/RootContainer.js';
import BreadcrumbsSaveActionBar from '../../components/BreadcrumbsSaveActionBar';

const Sections = (props) => {
  const { section } = useParams();
  switch (section) {
    case 'products':
      return <Products {...props} />;
    default:
      return <Details {...props} />;
  }
};

export const OrganizationEdit = () => {
  const {
    path,
    url,
    params: { orgName },
  } = useRouteMatch();

  // have to get this separate to keep the path and url sans section for the nested section router below
  const sectionMatch = useRouteMatch('/organization/:orgName/:section');
  const {
    fire: updateOrg,
    error: putError,
    isLoading: putLoading,
    request: requestUpdate,
  } = useApiPut('/organizations');
  const section = sectionMatch?.params?.section;
  const { loading, error, org, refreshOrg } = useGetOrgByName(orgName);
  const [isDirty, setIsDirty] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [formikValue, setFormikValue] = useState({});
  const [orgToDisplay, setOrgToDisplay] = useState({});

  useEffect(() => {
    setOrgToDisplay(org);
  }, [org]);

  useEffect(() => {
    if (putError) {
      toast.error(
        typeof putError === 'string'
          ? putError
          : 'An unexpected error occurred.'
      );
    }
    if (requestUpdate.isSuccess) {
      toast.success('Organization products successfully updated.');
    }
  }, [putError, requestUpdate.isSuccess]);

  // callback function called everytime when any trigger happend in formik
  const getFormikDetailsValue = (value, dirty) => {
    setIsDirty(dirty);
    setFormikValue(value);
  };

  // retain the state value while switching tab
  const sectionTabClicked = () => {
    setOrgToDisplay(formikValue);
    if (isDirty) {
      setIsTab(true);
    }
  };

  // trigger when save button clicked
  const handleSubmit = async () => {
    setIsTab(false);
    setOrgToDisplay(formikValue);
    await updateOrg(formikValue);
    await refreshOrg();
  };

  if (loading) {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <Loader />
      </div>
    );
  }

  let crumbsTrail = {
    Admin: null,
    Organizations: '/organizations',
  };

  crumbsTrail[org ? orgName : 'N/A'] = url;
  if (section) {
    let sectionTitle = 'N/A';
    //eslint-disable-next-line default-case
    switch (section) {
      case 'details':
        sectionTitle = 'Profile';
        break;
      case 'products':
        sectionTitle = 'Products';
        break;
    }
    crumbsTrail[sectionTitle] = `${url}/${section}`;
  }

  return (
    <RootContainer>
      <PageTitle title='Edit Organization' />
      <BreadcrumbsSaveActionBar
        crumbsTrail={crumbsTrail}
        onSubmit={handleSubmit}
        isDirty={isDirty || isTab}
        isSubmitting={putLoading}
      />
      <br />
      <div>
        <SectionNavigation
          sectionTabClicked={sectionTabClicked}
          sections={[
            {
              text: 'Profile',
              label: 'details',
              url: `${url}/details`,
            },
            {
              text: 'Product Access',
              label: 'products',
              url: `${url}/products`,
            },
          ]}
        />
        <div>
          {loading ? (
            <Loader />
          ) : !orgName || (error && error !== 'TOKEN FAILURE') ? (
            <ErrorScreen
              title='Not Found'
              code='404'
              message='The organization you were looking for does not exist'
              fontSize='.75rem'
            />
          ) : (
            <Switch>
              <Route path={`${path}/:section`}>
                <Sections
                  org={orgToDisplay}
                  getFormikDetailsValue={getFormikDetailsValue}
                />
              </Route>
              <Redirect to={`${path}/details`} />
            </Switch>
          )}
        </div>
      </div>
    </RootContainer>
  );
};
