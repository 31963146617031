import React, { useState } from 'react';
import { Grid, Chip, Typography } from '@mui/material';
import TextInput from '../../components/formik/TextInput';
import Checkbox from '../../components/formik/Checkbox';
import { makeStyles } from '@mui/styles';
import { useAuthContext } from '../../utils/auth/hooks';
import { CIS_INTERNAL } from '../../utils/auth/constants';

const useStyles = makeStyles((theme) => ({
  cell: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'space-around',
  },
  defineButton: {
    height: '2rem',
    alignSelf: 'center',
  },
  box: {
    backgroundColor: 'white',
    margin: 4,
    padding: 4,
  },
  chip: {
    width: '100%',
    paddingTop: 2,
    color: theme.palette.neutrals.medium,
  },
  chipRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  chipLabel: {
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    textOverflow: 'clip',
  },
}));

function Overview({ detailsRequest, formikProps, access }) {
  const classes = useStyles();
  const { roleIsAtLeast } = useAuthContext();
  const accountType = detailsRequest?.data?.accountType;
  const libraryType = detailsRequest?.data?.libraryType;
  const inputsDisabled = access === 'view';

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} lg={6} className={classes.cell}>
        <Grid item xs={12}>
          <TextInput
            label='Name'
            name='name'
            variant='filled'
            disabled={inputsDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label='Description'
            name='desc'
            variant='filled'
            multiline
            rows={4}
            disabled={inputsDisabled}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} lg={6} columnSpacing={2}>
        <Grid item container xs={12} columnSpacing={2}>
          <Grid item xs={12} md={6}>
            <Checkbox
              label='Table Locked'
              name='locked'
              disabled={inputsDisabled}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Checkbox
              label='External Sync'
              name='externalSync'
              disabled={inputsDisabled}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Checkbox
              label='Allow User Upload'
              name='allowUserUpload'
              disabled={inputsDisabled}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Checkbox
              label='Allow ResRule Edit'
              name='allowResruleEdit'
              disabled={inputsDisabled}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Checkbox
              label='Allow Selected Users'
              value={false}
              disabled={inputsDisabled}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Checkbox
              label='Allow User Edit'
              name='allowUserEdit'
              disabled={inputsDisabled}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Checkbox
              label='Allow API Edits'
              value={false}
              disabled={inputsDisabled}
            />
          </Grid>
          {roleIsAtLeast(CIS_INTERNAL) && (
            <Grid item xs={12} md={6}>
              <Checkbox
                label='Internal Use Only'
                name='internalUserOnly'
                disabled={inputsDisabled}
              />
            </Grid>
          )}
        </Grid>
        <Grid item container xs={12} columnSpacing={2} rowSpacing={2}>
          <StyledChip
            preface='Table Type: '
            text={formikProps?.values?.tableType}
          />
          <StyledChip
            preface='Functional Point: '
            text={formikProps?.values?.functionalPoint}
          />
          <StyledChip
            preface='Library Type: '
            text={
              formikProps?.values?.libraryType === null
                ? 'Local'
                : formikProps?.values?.libraryType
            }
          />
          <StyledChip
            preface='Category: '
            text={formikProps?.values?.category}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function StyledChip({ preface, text }) {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6}>
      <Chip
        className={classes.chip}
        variant='outlined'
        sx={{ color: 'neutrals.medium', bgColor: '#FFFFFF' }}
        label={
          <Typography>
            <span>
              <b>{preface}</b>
              {text}
            </span>
          </Typography>
        }
        classes={{ root: classes.chipRoot, label: classes.chipLabel }}
      />
    </Grid>
  );
}

export default Overview;
