import React, { useRef, useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';

export default function IQCXAcctSelectAdmin({
  multiple,
  limitTags,
  name,
  parentValue, // Expected to be the string id, such as '10381'
  onChange,
  style,
  className,
  orgConfig,
}) {
  const orgIQCXAccts = orgConfig?.iQCX?.accts;
  const queryInputRef = useRef(null);

  const nullValue = { label: '', value: '', type: '' };

  // Create a list of the options to autocomplete to.
  let optionsList = [];
  if (Array.isArray(orgIQCXAccts)) {
    optionsList = orgIQCXAccts.map((acct) => ({
      label: `${acct.label} - ${acct.value}`,
      value: acct.value,
      type: acct.type,
    }));
  }

  // This groups shared libraries and customers together.
  optionsList = [
    ...optionsList.filter((f) => f.type === 'Customer'),
    ...optionsList.filter((f) => f.type === 'Shared Library'),
    nullValue,
  ];

  const value = useMemo(() => {
    if (Array.isArray(orgIQCXAccts)) {
      const acct = orgIQCXAccts.find((acct) => acct.value === parentValue);
      if (acct) {
        return acct;
      }
    }
    return nullValue;
  }, [orgIQCXAccts, parentValue, nullValue]);

  return (
    <Autocomplete
      multiple={!!multiple}
      limitTags={isNaN(limitTags) ? -1 : limitTags}
      name={name}
      options={optionsList}
      groupBy={(option) => option.type}
      value={value}
      onChange={onChange}
      filterSelectedOptions={true}
      noOptionsText={
        queryInputRef?.current?.value
          ? 'No accounts match your search'
          : 'Type to search accounts'
      }
      className={className}
      style={style}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          label='iQCX Accounts'
          placeholder='Search available iQCX Accounts'
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
          inputRef={queryInputRef}
        />
      )}
    />
  );
}
