import React, { useEffect } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import { Divider, Tooltip, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ToggleAccordion from '../../../components/formik/ToggleAccordion';
import IBankUserSelect from '../../../components/IBankUserSelect';
import HelpIcon from '@mui/icons-material/Help';
import { CIS_INTERNAL } from '../../../utils/auth/constants';
import { useAuthContext } from '../../../utils/auth/hooks';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '26px',
    fontWeight: '300',
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: '-.5rem',
    color: theme.palette.text.secondary,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  helpIcon: {
    fontSize: '1.25rem',
    marginLeft: '.5rem',
    marginTop: '.25rem',
    color: theme.palette.secondary.main,
  },
}));

export default function Products({ user, isMe, getFormikDetailsValue }) {
  const classes = useStyles();
  const { hasProduct } = useAuthContext();

  // Get all the props from formik context
  const AutoProductsForm = () => {
    const { values, dirty } = useFormikContext();
    useEffect(() => {
      getFormikDetailsValue(values, dirty);
    }, [values, dirty]);

    return null;
  };

  const shouldBeDisabled = (product) => {
    if (isMe) {
      return 'You cannot modify your own access';
    } else if (user?.role === CIS_INTERNAL) {
      return "You cannot modify a Cornerstone Internal user's access";
    } else if (!hasProduct(product)) {
      return 'You do not have access to modify this product';
    }

    return false;
  };

  const shouldBeExpandable = (product) => {
    return user?.role !== CIS_INTERNAL && hasProduct(product);
  };

  return (
    <Formik
      initialValues={{
        config: {
          iBank: {
            active: false,
            users: [],
            ...user?.config?.iBank,
          },
          riskMessenger: {
            active: false,
            ...user?.config?.riskMessenger,
          },
        },
        ...user,
      }}
      enableReinitialize={true}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <AutoProductsForm />
          <p className={classes.subtitle}>
            {isMe ? 'View your' : "Modify the user's"} ability to access
            different Cornerstone products.
          </p>
          <ToggleAccordion
            name='config.iBank.active'
            value={
              user?.role === CIS_INTERNAL ? true : values?.config?.iBank?.active
            }
            setFieldValue={setFieldValue}
            label='iBank'
            disabled={shouldBeDisabled('iBank')}
            expandable={shouldBeExpandable('iBank')}
          >
            {!values?.config?.iBank?.users?.length && (
              <>
                <Alert severity='warning'>
                  This user has access to iBank but does not have any iBank
                  users configured to allow them to use single sign-on.
                </Alert>
                <br />
              </>
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '1.2rem' }}>User Access</span>
              <Tooltip
                title='Define which iBank user(s) this user should have login access to.'
                placement='right-end'
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </div>
            <IBankUserSelect
              multiple={true}
              name='config.iBank.users'
              value={values?.config?.iBank?.users || []}
              onChange={(e, value) => {
                setFieldValue('config.iBank.users', value);
              }}
              style={{ marginTop: '1rem', maxWidth: '850px' }}
            />
          </ToggleAccordion>
          <br />
          <ToggleAccordion
            name='config.riskMessenger.active'
            value={
              user?.role === CIS_INTERNAL
                ? true
                : values?.config?.riskMessenger?.active
            }
            setFieldValue={setFieldValue}
            label='Risk Messenger'
            disabled={shouldBeDisabled('riskMessenger')}
            expandable={shouldBeExpandable('riskMessenger')}
          />
          <Divider className={classes.divider} />
        </Form>
      )}
    </Formik>
  );
}
