import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { makeStyles } from '@mui/styles';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Card,
  Container,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
    marginLeft: '0px',
    padding: theme.spacing(2),
    width: '100%',
  },
  dropDown: {
    minWidth: '95px',
  },
  container: {
    width: '100%',
  },
}));

const CardList = ({ columns, data, children }) => {
  const {
    prepareRow,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    page,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data: Array.isArray(data) ? data : [],
    },
    useSortBy,
    usePagination
  );
  const classes = useStyles();

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <div className={classes.container}>
      {children(page, prepareRow)}
      <Card className={classes.card}>
        <Grid
          container
          justifyContent='space-between'
          textAlign='center'
          alignItems='center'
        >
          <Grid item xs={3}>
            <Button onClick={previousPage} disabled={!canPreviousPage}>
              <ChevronLeftIcon />
            </Button>
          </Grid>
          <Grid
            item
            container
            xs={6}
            spacing={1}
            justifyContent='center'
            textAlign='center'
            display='flex'
            alignItems='center'
          >
            <Grid item xs='auto'>
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </Grid>
            <Grid item xs='auto'>
              <FormControl className={classes.dropDown}>
                <InputLabel>Page Size</InputLabel>
                <Select
                  value={pageSize}
                  label='Page Size'
                  onChange={onChangeInSelect}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={nextPage}
              disabled={!canNextPage}
              classes={classes.rightButton}
            >
              <ChevronRightIcon />
            </Button>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default CardList;
