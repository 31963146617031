import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Checkbox, FormControlLabel } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  logoUploadWrapper: {
    margin: '10px 0 10px 0',
  },
  logoUploadPreviewWrapper: {
    width: 'fit-content',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #C6C6C6',
  },
  logoUploadBtn: {
    margin: '10px 0 10px 0',
    borderRadius: '50px',
  },
  defaultLogoWrapper: {
    display: 'table',
  },
}));

function UploadLogo(props) {
  const {
    logoSelected,
    setLogoSelected,
    setIsdirty,
    logo,
    setLogo,
    isDefaultLogo,
    setIsDefaultLogo,
  } = props;
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    setLogoSelected(logoSelected);
    setIsDefaultLogo(false);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onSelectFile = async (e) => {
    setIsdirty(true);
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // using the first image of DOM
    const base64 = await convertBase64(e.target.files[0]);
    props.setLogoSelected(base64);
    setSelectedFile(e.target.files[0]);
    //setLogo(base64);
  };

  const classes = useStyles();

  return (
    <div className={classes.logoUploadWrapper}>
      <div className={classes.logoUploadPreviewWrapper}>
        {selectedFile ? (
          <img src={preview} className='preview' />
        ) : (
          <img src={logoSelected} className='logo-selected' />
        )}
      </div>
      <Button
        className={classes.logoUploadBtn}
        variant='contained'
        component='label'
        color='primary'
      >
        + Upload Logo
        <input id='fileUpload' type='file' hidden onChange={onSelectFile} />
      </Button>
      <FormControlLabel
        className={classes.defaultLogoWrapper}
        control={
          <Checkbox
            checked={
              logoSelected ===
              process.env.REACT_APP_STATIC_ASSETS_URL + 'cis-logo-color.png'
            }
            label='Set to default Cornerstone Logo'
            onClick={() => {
              setLogoSelected(
                process.env.REACT_APP_STATIC_ASSETS_URL + 'cis-logo-color.png'
              );
              setPreview(
                process.env.REACT_APP_STATIC_ASSETS_URL + 'cis-logo-color.png'
              );
              setIsDefaultLogo(true);
            }}
          />
        }
        label='Set to Cornerstone Logo'
        labelPlacement='end'
      />
    </div>
  );
}

export default UploadLogo;
